import React from 'react';
import './App.css';

import { Link } from 'react-router-dom';

import logo from './images/1x/GALDISA_IMAGOTIPO_Blanco.png';
import icono from './images/1x/galdisa_icono.png';
import email from './images/iconos/mail.png';
import phone from './images/iconos/phone.png';

import { ReactComponent as IconFacebook } from './images/iconos/icon_facebook.svg';
import { ReactComponent as IconYoutube } from './images/iconos/icon_youtube.svg';
import { ReactComponent as IconLinkedIn } from './images/iconos/icon_linkedin.svg';

import dict from './dict/MenuSup';

class PieDePagina extends React.Component {

  render() {
    return (
      <div className="PieDePagina">
        <div className="footer-info">
          <div className="footer-left">
            <Link className="Link selected" to="/">HOME</Link>
            <Link className="Link" to="/aboutus">{dict[this.props.selectedLanguage].aboutus}</Link>
            <Link className="Link" to="/products">{dict[this.props.selectedLanguage].productos}</Link>
            <Link className="Link" to="/services">{dict[this.props.selectedLanguage].servicios}</Link>
            <Link className="Link" to="/blog">BLOG</Link>
            <Link className="Link" to="/socialresp">{dict[this.props.selectedLanguage].contact}</Link>
            <div className="footer-social">
              <a href="https://www.facebook.com/GaldisaMX/" rel="noopener noreferrer" target="_blank"><IconFacebook className="share_icon" /></a>
              <a href="https://www.linkedin.com/company/galdisa/" rel="noopener noreferrer" target="_blank"><IconLinkedIn className="share_icon" /></a>
              <a href=" https://www.youtube.com/channel/UCTXXTjXbQn2LtvMxrZImuVA/featured" rel="noopener noreferrer" target="_blank"><IconYoutube className="share_icon" /></a>
            </div>
          </div>
          <div className="footer-center">
            <img src={logo} alt="Logo galdisa" />
          </div>
          <div className="footer-right texto-m">
            <div className="footer-data texto-white">
              <span><img src={phone} style={{ width: '2rem' }} /> </span>
              <span className="texto-bold">+52 55 56 30 46 16</span>
              <br />
            </div>
            <div className="footer-data texto-white">
              <span>{this.props.selectedLanguage === 'es' ? "Oficinas corporativas" : "Corporate office"}:</span>
              <span>Camino Sta. Teresa 1040 Piso 9 int 902</span>
              <span>Jardines en la Montaña.</span>
              <span>Tlalpan, CDMX 14210, México</span>
              <br />
            </div>
            <div className="footer-data texto-white">
              <span><img src={email} style={{ width: '2rem' }} /></span>
              <span>ventas@galdisa.com</span>
              <span>info@galdisa.com</span>
            </div>
          </div>
        </div>
        <div className="footer-final">
          <Link className="Link privacy texto-bold texto-green" to='/aviso' >{this.props.selectedLanguage === `es` ? "AVISO DE PRIVACIDAD" : "PRIVACY NOTICE"}</Link>
          <div className="copyright">
            <img src={icono} alt="Icono Galdisa" />
            <span className="copyright">Galdisa® 2020. {this.props.selectedLanguage === `es` ? "Todos los derechos reservados." : "All rights reserved"}</span>
            <img src={icono} alt="Icono Galdisa" />
          </div>
        </div>
      </div>
    )
  }
}
export default PieDePagina;
